import React, { useState } from 'react'
import {
  Button,
  CenterWrapper,
  View,
  logger,
  Theme,
  variantProvider,
} from '@/app'
import { Page, NoItems } from '@/shared'
import {
  CardComponent,
  Carousel,
  ToggleComponent,
  ProfileAbout,
  TitleComponent,
} from '@/shared'
import { onMount, onUpdate, useSearchParams } from '@codeleap/common'
import { useListings, useProfiles } from '@/services/api'
import { CardsSkeleton, ProfileSkeleton } from '@/skeletons'

const Profile = ({ id }) => {
  const {
    data: { listings, listingsByQuery, listingsItems, listingsSold },
    queries: Listings,

    loading,
  } = useListings()

  const {
    data: { profile: profileState },
    queries: Profiles,
    loading: profileLoading,
  } = useProfiles()

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    draggable: true,
    slidesToShow: 1.2,
    slidesToScroll: 1,
  }

  const [params, setParams] = useSearchParams({
    tab: 1,
  })

  const isSmall = Theme.hooks.down('mid')

  onMount(() => {
    Profiles.getDetail.send(id)
  })

  onUpdate(() => {
    if (listingsByQuery.profile.count < 1) {
      Listings.getByTypes.send({ profile: id, limit: 888888 })
    }
  }, [profileState])

  const forSaleListings = listingsItems?.results

  const soldListings = listingsSold?.results

  logger.log({ listingsSold })

  const renderCards = (listingsArr) => {
    if (isSmall) {
      return (
        <Carousel {...settings}>
          {listingsArr.map((listing, index) => (
            <CardComponent key={index} listing={listing} />
          ))}
        </Carousel>
      )
    } else {
      return (
        <View variants={['fullWidth']} css={styles.cardsWrapper}>
          {listingsArr.map((listing, index) => (
            <CardComponent key={index} listing={listing} />
          ))}
        </View>
      )
    }
  }

  const CardsContainer = ({ children }) => {
    if (isSmall) {
      return (
        <View css={styles.sliderContainer} variants={['fullWidth']}>
          {children}
        </View>
      )
    } else {
      return (
        <CenterWrapper
          styles={
            {
              // innerWrapper: !loading && styles.cardsWrapper,
            }
          }
        >
          {children}
        </CenterWrapper>
      )
    }
  }

  return (
    <>
      <TitleComponent title={profileState?.username} />
      <View variants={['column', 'fullWidth', 'marginTop:12']}>
        <CenterWrapper>
          <View
            variants={['justifySpaceBetween', 'fullWidth', 'marginVertical:3']}
          >
            <ProfileSkeleton ready={!profileLoading}>
              <ProfileAbout profile={profileState} bigRadius />
            </ProfileSkeleton>
          </View>
        </CenterWrapper>
        <CenterWrapper styles={{ innerWrapper: styles.innerWrapper }}>
          <ToggleComponent
            firstTitle={'For sale'}
            secondTitle={'Sold'}
            tab={params.tab}
            setTab={setParams}
          />
        </CenterWrapper>
        <View
          css={styles.tabsWrapper}
          variants={['fullWidth', 'marginVertical:4']}
        >
          {params.tab == 1 && (
            <CardsContainer>
              <CardsSkeleton ready={!loading}>
                {forSaleListings?.length > 0 ? (
                  <>{renderCards(forSaleListings)}</>
                ) : (
                  <View css={styles.noCardsWrapper} variants={['fullWidth']}>
                    <NoItems
                      icon={'noChat'}
                      title={"You've got nothing here"}
                      subtitle={
                        'Make or accept an offer to start a conversation with a seller or a buyer.'
                      }
                      wrapperStyle={styles.noItemsWrapper}
                    />
                  </View>
                )}
              </CardsSkeleton>
            </CardsContainer>
          )}
          {params.tab == 2 && (
            <CardsContainer>
              <CardsSkeleton ready={!loading}>
                {soldListings?.length > 0 ? (
                  soldListings?.map((listing, index) => (
                    <CardComponent key={index} listing={listing} />
                  ))
                ) : (
                  <View css={styles.noCardsWrapper} variants={['fullWidth']}>
                    <NoItems
                      icon={'noChat'}
                      title={"You've got nothing here"}
                      subtitle={
                        'Make or accept an offer to start a conversation with a seller or a buyer.'
                      }
                      wrapperStyle={styles.noItemsWrapper}
                    />
                  </View>
                )}
              </CardsSkeleton>
            </CardsContainer>
          )}
        </View>
      </View>
    </>
  )
}

export default Profile

const NUM_COL = 3

const styles = variantProvider.createStylesheet({
  buttonsWrapper: {
    width: '12%',
  },
  noCardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardsWrapper: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${NUM_COL}, calc(${100 / NUM_COL}% - ${
      (Theme.spacing.value(2) * (NUM_COL - 1)) / NUM_COL
    }px)) `,
    gap: Theme.spacing.value(2),
    [Theme.media.down('mid')]: {
      display: 'flex',
      gap: Theme.spacing.value(2),
    },
  },
  tabsWrapper: {
    // minHeight: '100vh',
  },
  noItemsWrapper: {
    maxWidth: '100%',
  },

  sliderContainer: {
    height: '100%',
    '.slick-track': {
      display: 'flex',
      gridGap: Theme.spacing.value(4),
      lineHeight: 'unset !important',
    },
    '.slick-list': {
      margin: 'unset !important',
      padding: 'unset !important',
      overflow: 'unset !important',
    },
    [Theme.media.down('small')]: {
      '.slick-track': {
        gridGap: Theme.spacing.value(2),
      },
    },
  },
})
