import { Button, View } from '@/app';
import { Page } from '@/shared';

import { onMount, onUpdate } from '@codeleap/common';
import { useListings, useProfiles } from '@/services/api';
import { useAppSelector } from '@/lib';

import Profile from './_profile';

const ProfilePage: React.FC = (props) => {
  
  // const { profile } = useAppSelector((store) => ({
  //   profile: store.Session.profile,
  // }));

  return (
    <Page
      withRouter
      basePath='/profile'
      title='Profile'
      center={false}
      renderContentWrapper={({ children, menuItems, styles }) => (
        <View variants={['fullWidth']}>{children}</View>
      )}
    >
      <Profile path={':id'} />
    </Page>
  );
};

export default ProfilePage;
